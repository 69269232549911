<template>
  <b-container fluid class="mb-6 mt-6 lesson-detail-page">
    <b-overlay :show="loading">
      <b-row>
        <b-col cols="3" v-if="surveyDetail">
          <div class="card card-custom gutter-b lesson-detail-page__left-panel">
            <div class="card-body p-0">
              <b-card
                overlay
                class="lesson-detail-page__left-panel__image-card"
                :img-src="surveyDetail.image.url || null"
                :img-height="200"
              >
                <b-card-text
                  class="lesson-detail-page__left-panel__image-card-title"
                >
                  {{ surveyDetail.name }}
                </b-card-text>
              </b-card>
              <div class="lesson-detail-page__left-panel__information">
                <b-container>
                  <b-row>
                    <b-col class="p-6">
                      <div class="d-flex">
                        <b-button
                          v-if="isWritePermission"
                          size="sm"
                          class="btn-edit mr-4"
                          @click="goToEdit"
                        >
                          <span class="svg-icon svg-icon-sm mr-0">
                            <inline-svg
                              class="svg-icon mr-0"
                              src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                            />
                          </span>
                        </b-button>
                        <b-button
                          pill
                          size="sm"
                          class="btn-status px-7"
                          :class="getStatus(surveyDetail.status)"
                          >{{ getStatus(surveyDetail.status) }}
                          <div v-if="surveyDetail.status === 3" class="beta">
                            TN
                          </div>
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="p-6">
                      <label for="" class="text-gray">Mã Khảo sát</label>
                      <div>{{ surveyDetail.code }}</div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="p-6">
                      <label for="" class="text-gray">Số lượng câu hỏi</label>
                      <div>
                        <span class="svg-icon svg-icon-xl">
                          <inline-svg
                            class="svg-icon"
                            src="/media/svg/icons/Neolex/Basic/Library.svg"
                          />
                        </span>
                        {{ surveyDetail.questionCount }} câu hỏi
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="p-6">
                      <label for="" class="text-gray">Cập nhật lần cuối</label>
                      <div>{{ formatDate(surveyDetail.updateDatetime) }}</div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="p-6">
                      <label for="" class="text-gray">Người cập nhật</label>
                      <div class="d-flex align-items-center">
                        <avatar
                          size="40px"
                          :text="surveyDetail.updaterName"
                          :rounded="true"
                        />
                        <div class="d-flex flex-column ml-5">
                          <p class="mb-0 font-weight-bolder">
                            {{ surveyDetail.updaterName }}
                          </p>
                          <p class="mb-0 text-gray">
                            {{ surveyDetail.updaterName }}
                          </p>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
            </div>
          </div>
        </b-col>
        <b-col>
          <b-card>
            <basic-subheader
              title="Danh mục câu hỏi"
              class="px-0 border-top-0 border-bottom wrap-header"
            />
            <div v-for="(section, index) in sections" :key="index" class="py-5">
              <h6 class="font-weight-bolder text-success">
                Phần {{ index + 1 }}: {{ section.name }}
              </h6>
              <p class="font-weight-bolder py-4">{{ section.description }}</p>
              <template v-for="(question, idx) in section.questions">
                <Question
                  :id="question.id"
                  :key="question.id"
                  :question="question"
                  :type="question.type"
                  is-detail
                  :questionNumber="idx + 1"
                  @view="viewQuestion"
                />
              </template>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
    <ModalQuestion
      v-if="showModal"
      type="DETAIL"
      :id="id"
      :question="questionDetail"
      @reset="resetModal"
    />
  </b-container>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import {
  GET_SURVEY_DETAIL,
  CHANGE_STATUS_SURVEY,
  RESET_STATE_SURVEY,
} from '@/core/services/store/survey.module';
const { mapActions, mapState, mapMutations } =
  createNamespacedHelpers('survey');
export default {
  name: 'SurveyDetail',
  components: {
    Question: () => import('./components/question.vue'),
    ModalQuestion: () => import('./components/ModalQuestion.vue'),
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      sections: [],
      questionDetail: null,
      showModal: false,
    };
  },
  computed: {
    ...mapState(['surveyDetail']),
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  created() {
    this.loadData();
  },
  beforeDestroy() {
    this.RESET_STATE_SURVEY();
  },
  methods: {
    ...mapActions({ GET_SURVEY_DETAIL, CHANGE_STATUS_SURVEY }),
    ...mapMutations({ RESET_STATE_SURVEY }),
    async loadData() {
      this.loading = true;
      await this.GET_SURVEY_DETAIL(this.id);
      if (this.surveyDetail) {
        this.sections = this.surveyDetail.sections;
      }
      this.loading = false;
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return 'inactive';
        case 1:
          return 'active';
        case 3:
          return 'active';

        default:
          return 'draft';
      }
    },
    resetModal() {
      this.typeModal = null;
      this.showModal = false;
      this.questionDetail = null;
    },
    viewQuestion(item) {
      this.questionDetail = item;
      this.showModal = true;
      this.$store.commit(`context/setModal`, true);
    },
    goToEdit() {
      this.$router.push({
        name: 'survey_edit',
        params: {
          id: this.id,
        },
      });
    },
    formatDate(date) {
      const isValid = this.$moment(date, 'MM/DD/YYYY HH:mm:ss').isValid();
      if (!isValid) return null;
      return this.$moment(date).format('DD/MM/YYYY');
    },
  },
};
</script>
<style lang="scss" scoped>
.text-gray {
  color: #b5b5c3;
}
.mw-400 {
  min-width: 400px;
}

.lesson-detail-page {
  .lesson-detail-page__left-panel {
    height: 100%;

    .lesson-detail-page__left-panel__image-card {
      margin-bottom: 20px;
      min-height: 200px;

      img {
        max-height: 200px;
      }

      .card-body {
        padding: 0;
        min-height: 200px;
      }

      .lesson-detail-page__left-panel__image-card-title {
        height: 60px;
        width: 100%;
        position: absolute;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        font-weight: bold;
        font-size: 16px;
        color: #ffffff;
        padding: 10px;
        overflow: hidden;
      }
    }

    .lesson-detail-page__left-panel__actions {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;

      .lesson-detail-page__left-panel__actions__button {
        height: 30px;
        width: 30px;

        .lesson-detail-page__left-panel__actions__button__icon {
          height: 12px;
          width: 12px;
        }
      }
    }
  }

  .lesson-detail-page__right-panel {
    height: 100%;
  }
}
</style>
<style lang="scss">
.btn-edit {
  background-color: #def2f1 !important;
  border-color: #def2f1 !important;
  color: #008479 !important;
}
.btn-delete {
  background-color: #ffebef !important;
  border-color: #ffebef !important;
  color: #ff5756 !important;
}
.btn-status {
  color: #fff !important;
  text-transform: capitalize;
  position: relative;

  &.active {
    background-color: #21a567 !important;
    border-color: #21a567 !important;
  }

  &.inactive {
    background-color: #ff5756 !important;
    border-color: #ff5756 !important;
  }

  &.draft {
    background-color: #888c9f !important;
    border-color: #888c9f !important;
  }

  .beta {
    background: #fed31b;
    border-radius: 12px;
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 2px 6px;
  }
}

.wrap-header {
  .subheader-content__title {
    color: #01645a;
  }
}
</style>
